<template xmlns="">
  <div class="container" :style="{ background: backgroundColorLigtht }">
    <div class="top-bg" :style="{ background: backgroundColor }"></div>
    <div class="white-card" v-show="whiteFlag">
      <van-swipe class="swipe-box" :autoplay="autoplay" @change="onSwipeChange"
                 ref="swipeRef" :touchable='true'>
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img :src="image" class="custom-swipe-item" :alt="'Slide ' + (index + 1)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="shooting-page" v-show="shootingFlag">
      <div class="time-box flex-center">
        <span class="countdown">倒计时: </span>
        <van-count-down :time="time" format="HH:mm:ss"/>
      </div>
      <div class="qrcode flex-center" >
        <qr-code ref="child" />
      </div>
      <div class="qrcode-text">长按可保存此二维码</div>
      <div class="shooting-code-box" :style="{ background: backgroundColor }">
        <div class="shooting-title">您的拍摄码为:</div>
        <div class="number-box flex-between">
          <div class="number" v-for="item in shootingNnumber">{{ item }}</div>
        </div>
        <div class="tips-text">温馨提示：如果退出此页面，请重新申请拍摄码</div>
      </div>
      <div class="tips-card-box">
        <div class="tips-top">
          <div class="title">温馨提示：</div>
          <div class="describe-box">
            <p>长按可保存拍摄到相册</p>
            <p>请根据自助机提示，在倒计时结束前进行操作</p>
            <p>倒计时结束页面将自动关闭</p>
            <p>需要再进入页面申请拍摄</p>
            <p>请在自助机完成拍摄后再点击“下一步”按钮</p></div>
        </div>
      </div>
    </div>
    <div class="next-step" :style="{ background: confirmColor }"  @click="handleNextStep">
      <div class="next-step-text" :style="{ background: confirmColor }">{{ stepName }}</div>
    </div>

    <div class="popup-box" v-show="isShowApproved">
      <div class="popup-top flex">
        <div class="ok-img" v-show="okFlag"></div>
        <div class="ng-img" v-show="ngFlag"></div>
        <div class="status-text">
          <span>{{approveResult}}</span>
          <br>
          <span>{{approveResultDetail}}</span>
        </div>
      </div>
      <div class="next-step-box">
        <div class="step-text step-text-b" :style="{ background: backgroundColor, width: boxWidth + '%'}" v-show="ngFlag" @click="reTakePhone">重新拍摄</div>
        <div class="step-text step-text-b" :style="{ background: backgroundColor, width: boxWidth + '%'}" @click="onCloseBox">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import { trustSign, getShootCode, renewShootCode, getResult, getJumpUrl} from "@/api/app-base.js";
import QrCode from "@/components/QrCode.vue";
export default {
  data() {
    return {
      currentIndex: 0,
      maxIndex: 0,
      autoplay: 4000,
      backgroundColor: '#000000',
      backgroundColorLigtht: '#000000',
      confirmColor: '#000000',
      images: [],
      playButtonUrl: "",
      isVisible: false,
      errorMessage: "",
      shootingFlag: false,
      whiteFlag: true,
      time: 0,
      shootingNnumber: [
          '', '', '', '', '', '', '', ''
      ],
      jumpFlag: false,
      approveFlag: true,
      isShowApproved: false,
      stepName: "",
      okFlag: false,
      ngFlag: false,
      approveResult: null,
      approveResultDetail: null,
      boxWidth: 40
    };
  },
  created() {
    this.init()
    this.setStyle();
    this.setImages();
  },
  components: {
    QrCode
  },
  methods: {
    init(){
      this.stepName="下一步"
      this.whiteFlag=true
      this.approveFlag=true
      this.isShowApproved = false
    },
    handleNextStep() {
      if(this.jumpFlag){
        if(this.okFlag){
          this.goJumpUrl()
        }
      }else{
        this.$refs.swipeRef.next()
        if (this.currentIndex === this.maxIndex) {
          this.shootingFlag=true
          this.whiteFlag=false
          this.signCheck()
        }
      }
    },
    onSwipeChange(index) {
      this.currentIndex = index;
      if (index === this.maxIndex) {
        this.autoplay=0
      }else{
        this.autoplay=4000
      }
    },
    playVideo() {
      this.$router.push('/videoplayer')
    },
    setStyle(){
      this.backgroundColor = this.$localStorage.get('styleColor')
      this.confirmColor = this.$localStorage.get('styleColor')
      this.backgroundColorLigtht = this.$localStorage.get('styleColorLight')
    },
    setImages(){
      const userDesc = this.$store.state.descImgMachine
      this.images = userDesc.split(',')
      this.maxIndex = this.images.length - 1;
    },
    signCheck(){
      const data = {
        sid: this.$store.state.sid
      }
      trustSign(data).then(res=>{
        if(res.code === 0) { 
          this.stepName = "审核中..."
          this.confirmColor = "#C0C0C0"
          if(res.data==="OK"){
            this.isVisible = false
            this.errorMessage = ""
            this.getShootingCode()
          }else{
            this.isVisible = true
            this.errorMessage = "服务错误"
          }
        }else{
          this.isVisible = true
          this.errorMessage = res.msg
        }
      },
      (error) => {
        this.isVisible = true
        if(error.response.data.msg != undefined){
          this.errorMessage = error.response.data.msg
        }else{
          this.errorMessage = "服务错误"
        }
      })
    },
    closeMessage(){
      this.isVisible = false
      this.errorMessage = ""
    },
    getShootingCode(){
      let data = {
        sid: this.$store.state.sid,
      }
      getShootCode(data).then(res=>{
        this.shootingNnumber = res.data.shootCode.shootCode
        this.time = res.data.shootCode.expireTime
        this.$refs.child.showQrcode(res.data.shootCode.shootCode);
        this.jumpFlag = true
        this.startPolling()
      },
      (error) => {
        this.isVisible = true
        if(error.response.data.msg != undefined){
          this.errorMessage = error.response.data.msg
        }else{
          this.errorMessage = "服务错误"
        }
      })
    },
    startPolling() {
      this.intervalID=setInterval(this.fetchData, 5000); 
    },
    stopPolling() {
      if (this.intervalID) {
        clearInterval(this.intervalID)
        this.intervalID=null
      }
    },
    fetchData() {
      const sid = this.$store.state.sid
      getResult(sid).then(res=>{
        if(res.code === 0){
          if(res.data.code != "0"){
            this.stopPolling()
            if(res.data.code == "1"){
              this.stepName = "下一步"
              this.jumpFlag = true
              this.confirmColor = this.$localStorage.get('styleColor')
              this.isShowApproved = true
              this.approveResult = '合格'
              this.approveResultDetail= '审核通过'
              this.okFlag = true
              this.ngFlag = false
              this.boxWidth = 96
            }else if(res.data.code == "2"){
              this.stepName = "审核不通过"
              this.approveResult = '审核不通过'
              if(res.data.reason != undefined){
                this.approveResultDetail= res.data.reason
              }else{
                this.approveResultDetail= ""
              }
              this.isShowApproved = trueyarn 
              this.approveFlag = false
              this.okFlag = false
              this.ngFlag = true
              this.boxWidth = 45
            }
          }else{
          }
        }
      })
    },
    goJumpUrl(){
      const data = {
        sid: this.$store.state.sid
      }
      getJumpUrl(data).then(res=>{
        if(res.code === 0){
          window.location.href=res.data
        }
      },
      (error) => {
        this.isVisible = true
        if(error.response.data.msg != undefined){
          this.errorMessage = error.response.data.msg
        }else{
          this.errorMessage = "服务错误"
        }
      })
    },
    reTakePhone(){
      const data = {
        sid: this.$store.state.sid
      }
      renewShootCode(data).then(res=>{
        if(res.code === 0){
          if(res.data){
            this.init()
            this.setStyle()
            this.setImages()
            this.shootingFlag=true
            this.whiteFlag=false
            this.signCheck()
            this.jumpFlag = false
            this.okFlag = false
            this.ngFlag = false
            this.approveResult = null
            this.approveResultDetail = null
          }
        }
      })
    },
    onCloseBox(){
      this.isShowApproved = false
    },
  }
}
</script>
<style lang="less" scoped>
.top-bg {
  height: 10rem;
  width: 100vw;
  border-radius: 0 0 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  z-index: -1;

  .white-card {
    width: 660px;
    height: 82%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
    border-radius: 24px;
    overflow-x: hidden;
    margin: 40px auto;
    position: relative;
    .info-box {
      width: 100%;
    }
  }
  .shooting-page {
    width: 660px;
    height: 82%;
    background: #FFFFFF;
    margin: 40px auto;
    position: relative;
    border-radius: 24px;
    .time-box {
      padding-top: 3vh;
      .van-count-down {
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 43px;
        color: #E60012;
        line-height: 27px;
      }
    }
    .countdown {
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 43px;
      color: #E60012;
      line-height: 27px;
      margin-right: 20px;
    }
    .qrcode {
      width: 257px;
      height: 257px;
      margin: 18px auto 0;
    }
    .qrcode-text {
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      margin-top: 10px;
    }
    .shooting-code-box {
      border-radius: 32px;
      margin: 16px 12px;
      padding: 40px 36px 26px 36px;
      .shooting-title {
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 33px;
        color: #000000;
        line-height: 30px;
      }
      .number-box {
        margin: 30px 0px;
        .number {
          width: 48px;
          position: relative; /* 设置相对定位 */
          margin-right: 20px;
          text-align: center;
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 46px;
          color: #000000;
        }
        .number::after {
          content: ""; /* 必须有内容才能显示伪元素 */
          display: block; /* 将伪元素转为块级元素 */
          width: 100%; /* 让伪元素与文本等宽 */
          height: 2px; /* 设置下划线高度 */
          background-color: #fff; /* 设置下划线颜色 */
          bottom: -5px; /* 调整下划线在文本上方的位置 */
          font-family: Source Han Sans CN;
          font-weight: 500;
          font-size: 55px;
          color: #000000;
          line-height: 30px;
        }
      }
      .tips-text {
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 30px;
        color: #FFFFFF;
        line-height: 30px;
      }
    }
    .tips-card-box {
      height: 30%;
      border-radius: 60px 60px 0 0;
      padding: 2vh 5vw 0px;
      background-color: #fff;
      .tips-top {
        .title {
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 36px;
          color: #000000;
        }
        .describe-box {
          font-family: Source Han Sans CN;
          font-weight: 500;
          font-size: 28px;
          color: #000000;
          line-height: 48px;
        }
      }
      .tips-bottom {
        background-color: #3d62ad;
        border-radius: 50px 50px 50px 50px;
        padding: 20px 0;
        font-family: Source Han Sans CN;
        font-weight: 700;
        font-size: 34px;
        color: #FFFFFF;
        text-align: center;
        margin: 75px 0 0 0;
        div {
          flex: 1;
        }
      }
    }
  }
  .next-step {
    padding: 16px 0;
    border-radius: 36px;
    text-align: center;
    margin: 36px auto 0;
    width: 90%;
    height: 5vh;
    display: flex;
    .next-step-text {
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      background-color: #3d62ad;
      border-radius: 36px;
      height: 5vh;
      margin: 0px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .popup-box {
    position: absolute;
    bottom: 0;
    padding-bottom: 0;
    border-radius: 40px 40px 0 0;
    background: whitesmoke;
    width: 100%;
    .popup-top {
      display: flex;
      align-items: center;
      padding-top: 2vh;
      margin-left: 20%;
      .ok-img {
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/images/right.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 30px;
      }
      .ng-img {
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/images/wrong.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 30px;
      }
      .status-text {
        margin-left: 4vw;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        font-size: 38px;
        color: #04c48b;
        line-height: 72px;
      }
    }
  }
  .next-step-box {
    padding: 8px 0;
    border-radius: 36px;
    text-align: center;
    margin: 16px auto 0;
    width: 90%;
    height: 5vh;
    display: flex;
    .step-text {
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      background-color: #3d62ad;
      border-radius: 36px;
      height: 5vh;
      margin: 0px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .step-text-a {
      width: 90%;
      height: 7vh;
    }
    .step-text-b {
      width: 40%;
      height: 7vh;
    }
  }
}
.swipe-box .van-swipe-item {
  position: relative;
  overflow: hidden;
  cursor: grab;
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
}

.van-dialog {
  position: fixed;
  top: 45%;
  left: 50%;
  width: 15rem;
  height: 14vh;
  overflow: hidden;
  font-size: 0.8rem;
  transf: translate3d(-50%,-50%,0);
  backface-visibility: hidden;
  transition: .3s;
  z-index: 9999;
  .header {
    height: 6vh;
    font-weight: 500;
    line-height: 8vh;
    text-align: center;
    vertical-align: bottom;
  }
  .footer {
    height: 6vh;
    padding-top: 0.5rem;
  }
  .van-button {
    border-top: 0.2px solid #d0d0d0;
  }
  .confirm-span {
    color: #1989fa;
  }
}
</style>
<style lang="less" scoped>
.white-card {
  /deep/ .van-swipe {
    /* 自定义样式 */
    height: 75vh;
  }
}

.swipe-box {
  margin: 0 32px;

  .van-swipe-item {
    background-color: #fff;
    //height: auto;
    padding-top: 16vh;
  }
    //height: 16vh;
  /deep/ .van-swipe__track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
//height: 16vh;