<template>
  <div id="code">
    <canvas id="canvas">
    </canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode';
export default {
  data() {
    return {
      srcImage: null,
      image: null,
    }
  },
  components: {
    QRcode: QRCode     //注册组件
  },
  methods: {
    showQrcode(url){
      this.getUrl(url);
    },
    getUrl(url) {
      const canvas = document.getElementById("canvas");
      const code = document.getElementById("code");
      const img = document.getElementById("img");
      if(img){
        img.remove();
      }
      QRCode.toCanvas(canvas, url, error => {
        if (error) console.error(error);
      });
      if(this.image){
        this.image.src = canvas.toDataURL("image/png");  //转换成base64格式路径的png图片
      }else{
        this.image = new Image();     //实例一个img
        this.image.src = canvas.toDataURL("image/png");  //转换成base64格式路径的png图片
        this.image.style.width = "100%";    //设置样式
        code.appendChild(this.image);     //添加到code 容器中
        canvas.style.display = "none";   //隐藏掉canvas
      }
      
    }
  },
  mounted() {
  }
}
</script>
<style>
#code {
  width: 257px;
  height: 257px
}
</style>
