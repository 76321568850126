/****   request.js   ****/
// 导入axios
import axios from 'axios';
import { Toast } from 'vant';

// const baseUrl = 'http://192.168.9.49:9801/api/h5'
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

//  创建新的axios实例
const request = axios.create({
  // baseURL: baseUrl,
  timeout: 50000,
})
// 请求拦截器
request.interceptors.request.use(config => {
 return config
},(error)=>{
return Promise.reject(error)
 })

// 响应拦截器
request.interceptors.response.use(res => {
  return res.data
}, function(error){
  return Promise.reject(error)
  // Toast.fail(error.response.data.message)
})

export default request
