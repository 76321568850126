<template>
  <div class="example-reference-page flex-column-center">
    <div class="title">范例参考</div>
    <div class="gray-bg-box flex-center">
      <img src="@/assets/images/example.png" alt="" />
    </div>
    <div class="color-portrait-box flex-center" :style="{ background: backgroundColor }">
      <div class="bg flex-center">
        <img class="info-portrait" :src="imageContent"  alt="" />
      </div>
    </div>
    <div class="button-box flex-between">
      <div class="left common flex-center" :style="{color: backgroundColor}" @click="getReshoot">重新拍摄</div>
      <div class="right common flex-center" :style="{color: backgroundColor}" @click="onSubmitToserve">提交预审</div>
    </div>
    <div class="van-overlay" style="z-index: 2005;" v-show="isVisible"></div>
    <div v-show="isVisible" role="dialog" class="van-dialog" aria-labelledby="错误">
        <div class="header">{{errorMessage}}</div>
        <div class="footer" @click="closeMessage">
            <button class="van-button van-button--default van-button--large van-dialog__confirm">
                <span class="confirm-span">确认</span>
            </button>
        </div>
    </div>
    <van-popup v-model="show" class="review-photos-box" style="background: none;">
      <div class="review-photos flex-column-center" v-show="isShowApproving">
        <div class="loading">
          <img class="rotating" src="@/assets/images/loading.png" alt="" />
        </div>
        <div class="waiting flex-center">正在审核照片，请稍等……</div>
      </div>
      <!--审核结果-->
      <div class="popup-box" v-show="isShowApproved">
        <div class="popup-top flex">
          <div class="ok-img" v-show="okFlag"></div>
          <div class="ng-img" v-show="ngFlag"></div>
          <div class="status-text">
            <span>{{approveResult}}</span>
            <br>
            <span>{{approveResultDetail}}</span>
          </div>
        </div>
        <div class="next-step-box" v-show="okFlag" @click="onNextStep">
          <div class="step-text step-text-a">下一步</div>
        </div>
        <div class="next-step-box" v-show="ngFlag" >
          <div class="step-text step-text-b" :style="{ background: backgroundColor }" @click="reTakePhone">重新拍摄</div>
          <div class="step-text step-text-b" :style="{ background: backgroundColor }" @click="onCloseBox">关闭</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { submitPhotos, getResult, getJumpUrl, renewShootCode} from "@/api/app-base.js";
export default {
  data(){
    return {
      show: false,
      isShowApproving: false,
      isShowApproved: false,
      okFlag: false,
      ngFlag: false,
      imageContent: "",
      backgroundColor: '#ffffff',
      backgroundColorLigth: '#ffffff',
      intervalID: null,
      isVisible: false,
      errorMessage: null,
      approveResult: null,
      approveResultDetail: null
    }
  },
  created() {
    this.init();
    this.setStyle();
    this.initImage();
  },
  methods: {
    init(){
      this.isShowApproving=false
      this.intervalID=null
    },
    setStyle(){
      this.backgroundColor = this.$localStorage.get('styleColor');
      this.backgroundColorLigth = this.$localStorage.get('styleColorLight');
    },
    initImage(){
      const imageUrl = this.$store.state.upload;
      if(imageUrl){
        this.imageContent = imageUrl;
      }else{
        this.imageContent = "";
      }
    },
    onSubmitToserve(){
      this.show = true
      this.isShowApproving = true
      const data = {
        upload: this.$store.state.upload,
        uploadFileName: this.$store.state.uploadFileName,
        sid: this.$store.state.sid
      }
      submitPhotos(data).then(res=>{
        if(res.code === 0){
          this.intervalID = null
          this.startPolling();
        }
      },
      (error) => {
        this.show = false
        this.isShowApproving = false
        this.isVisible = true
        if(error.response.data.msg != undefined){
          this.errorMessage = error.response.data.msg
        }else{
          this.errorMessage = "服务错误"
        }
      })
    },
    getReshoot(){
      this.$router.go(-1);
    },
    onNextStep(){
      this.goJumpUrl()
    },
    startPolling() {
      this.intervalID=setInterval(this.fetchData, 5000); 
    },
    stopPolling() {
      if (this.intervalID) {
        clearInterval(this.intervalID)
        this.intervalID=null
      }
    },
    fetchData() {
      const sid = this.$store.state.sid
      getResult(sid).then(res=>{
        if(res.code === 0){
          if(res.data.code != "0"){
            this.show = true
            this.pollingInterval = false
            this.stopPolling()
            if(res.data.code == "1"){
              this.isShowApproved = true
              this.isShowApproving = false
              this.approveResult = '合格' 
              this.approveResultDetail= '审核通过'
              this.okFlag = true
              this.ngFlag = false
            }else if(res.data.code == "2"){
              this.isShowApproved = true
              this.isShowApproving = false
              this.approveResult = '审核不通过'
              if(res.data.reason != undefined){
                this.approveResultDetail= res.data.reason
              }else{
                this.approveResultDetail= ""
              }
              this.okFlag = false
              this.ngFlag = true
            }
          }
        }
      },
      (error) => {
      })
    },
    beforeDestroy() {
      this.stopPolling();
      this.intervalID=null
    },
    closeMessage(){
      this.isVisible = false
      this.errorMessage = ""
    },
    reTakePhone(){
      const data = {
        sid: this.$store.state.sid
      }
      renewShootCode(data).then(res=>{
        if(res.code === 0){
          if(res.data){
            this.$router.push("/specificationdetails");
          }
        }
      })
    },
    onCloseBox(){
      this.show=false
      this.isVisible = false
      this.isShowApproved = false
      this.isShowApproving = false
      this.okFlag = false
      this.ngFlag = false
      this.errorMessage = ""
    },
    goJumpUrl(){
      const data = {
        sid: this.$store.state.sid
      }
      getJumpUrl(data).then(res=>{
        if(res.code === 0){
          window.location.href=res.data
        }
      },
      (error) => {
        this.isVisible = true
        if(error.response.data.msg != undefined){
          this.errorMessage = error.response.data.msg
        }else{
          this.errorMessage = "服务错误"
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.example-reference-page {
  width: 100vw;
  padding: 100px 0 100px;
  .title {
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-size: 38px;
    color: #000000;
    margin-bottom: 40px;
  }
  .portrait-box {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/portrait.png");
    background-size: cover;
    background-repeat: no-repeat;
    .portrait-border {
      width: 330px;
      height: 350px;
      margin: 180px auto 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .gray-bg-box {
    width: 12rem;
  }
  .color-portrait-box {
    width: 70%;
    //height: calc( 100% - 100px);
    min-height: 800px;
    background: #326EDE;
    border-radius: 12px;
    margin-top: 60px;
    box-sizing: border-box;
    .bg {
      width: 550px;
      height: 600px;
    }
    .info-portrait {
      //width: 500px;
      height: 550px;
    }
  }
  .button-box {
    width: 600px;
    margin: 20px 40px;
    .left {
      width: 185px;
      height: 77px;
      background: #fffafa;
      box-shadow: 0px 12px 9px 0px rgba(0,0,0,0.28);
      border-radius: 12px;
      //color: #fff;
    }
    .right {
      width: 185px;
      height: 77px;
      background: #fffafa;
      box-shadow: 0px 12px 9px 0px rgba(0,0,0,0.28);
      border-radius: 12px;
      //color: #87B3E0;
    }
    .common {
      font-family: Adobe Heiti Std;
      font-weight: bold;
      font-size: 36px;
      //line-height: 17px;
    }
  }
  .review-photos {
    margin-top: 50%;
    .loading {
      width: 246px;
      height: 246px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .waiting {
      width: 100vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 42px;
      color: #ffffff;
      margin-top: 150px;
      font-weight: bold;
    }
  }
  //通过
  .popup-box {
    height: 25vh;
    position: sticky;
    padding-bottom: 0;
    border-radius: 40px 40px 0 0;
    background: white;
    top: 100%;
    .popup-top {
      display: flex;
      align-items: center;
      padding-top: 2vh;
      margin-left: 20%;
      .ok-img {
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/images/right.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 30px;
      }
      .ng-img {
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/images/wrong.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 30px;
      }
      .status-text {
        margin-left: 4vw;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        font-size: 28px;
        color: #04c48b;
        line-height: 72px;
      }
    }
  }
  .next-step-box {
    padding: 8px 0;
    //background-color: #3d62ad;
    border-radius: 36px;
    text-align: center;
    margin: 16px auto 0;
    width: 90%;
    display: flex;
    .step-text {
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      background-color: #3d62ad;
      border-radius: 36px;
      height: 5vh;
      margin: 36px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .step-text-a {
      width: 90%;
      height: 7vh;
    }
    .step-text-b {
      width: 40%;
      height: 7vh;
    }
  }
}
.review-photos-box {
  width: 100%;
  height: 100%;
  top: 50%;
  .van-popup {
    .van-popup--center {
      top: 58%;
    }
  }
}
.van-dialog {
  position: fixed;
  top: 45%;
  left: 50%;
  width: 15rem;
  height: 14vh;
  overflow: hidden;
  font-size: 0.8rem;
  transf: translate3d(-50%,-50%,0);
  backface-visibility: hidden;
  transition: .3s;
  z-index: 9999;
  .header {
    height: 6vh;
    font-weight: 500;
    line-height: 8vh;
    text-align: center;
    vertical-align: bottom;
  }
  .footer {
    height: 6vh;
    padding-top: 0.5rem;
  }
  .van-button {
    border-top: 0.2px solid #d0d0d0;
  }
  .confirm-span {
    color: #1989fa;
  }
}
.rotating {
  animation: spin 2s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style lang="less">
/* 自定义Popup样式 */
.custom-popup .van-overlay::before {
  border-radius: 30px 30px 0 0!important; /* 修改圆角大小 */
}
.review-photos-box {
 /deep/ .van-popup {
    background: none !important;
  }
}
</style>
