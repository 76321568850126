<template xmlns="">
  <div class="container" :style="{ background: backgroundColorLigth }">
    <div class="top-bg" :style="{ background: backgroundColor }"></div>
    <div class="white-card">
      <van-swipe class="swipe-box" :autoplay="autoplay" @change="onSwipeChange"
                 ref="swipeRef" :touchable='true'>
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img :src="image" class="custom-swipe-item" :alt="'Slide ' + (index + 1)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="next-step-box" :style="{ background: backgroundColor }" @click="handleNextStep">
      <div class="step-text">
        {{ stepName }}
      </div>
    </div>
    <div class="van-overlay" style="z-index: 2005;" v-show="isVisible"></div>
    <div v-show="isVisible" role="dialog" class="van-dialog" aria-labelledby="错误">
      <div class="header">{{errorMessage}}</div>
      <div class="footer" @click="closeMessage">
        <button class="van-button van-button--default van-button--large van-dialog__confirm">
          <span class="confirm-span">确认</span>
        </button>
      </div>
    </div>
    <img class="video-describe" src="@/assets/images/video.png" @click="playVideo" alt="Dynamic Image">
    <span class="video-describe-span" :style="{ color: backgroundColor }" @click="playVideo">视频讲解</span>
  </div>
</template>
<script>
import { trustSign } from "@/api/app-base.js";
export default {
  data() {
    return {
      currentIndex: 0,
      maxIndex: 0,
      autoplay: 4000,
      backgroundColor: 'blue',
      backgroundColorLigth: 'blue',
      images: [],
      isVisible: false,
      errorMessage: "",
      intervalID: null,
      stepName: "下一步"
    };
  },
  created() {
    this.setStyle();
    this.setImages();
  },
  methods: {
    handleNextStep() {
      this.$refs.swipeRef.next()
      if (this.currentIndex === this.maxIndex) {
        this.signCheck()
      }
    },
    onSwipeChange(index) {
      this.currentIndex = index;
      if (index === this.maxIndex) {
        this.autoplay=0
        this.stepName = "已知晓, 开始拍摄"
      }else{
        this.autoplay=4000
        this.stepName = "下一步"
      }
    },
    playVideo() {
      this.$router.push('/videoplayer')
    },
    setStyle(){
      this.backgroundColor = this.$localStorage.get('styleColor')
      this.backgroundColorLigth = this.$localStorage.get('styleColorLight')
    },
    setImages(){
      const userDesc = this.$store.state.descImg;
      this.images = userDesc.split(',')
      this.maxIndex = this.images.length - 1;
    },
    signCheck(){
      const data = {
        sid: this.$store.state.sid
      }
      trustSign(data).then(res=>{
        if(res.code === 0) { 
          if(res.data==="OK"){
            this.isVisible = false
            this.errorMessage = ""
            this.$router.push("/specificationdetails");
          }else{
            this.isVisible = true
            this.errorMessage = "服务错误"
          }
        }else{
          this.isVisible = true
          this.errorMessage = res.msg
        }
      },
      (error) => {
        this.isVisible = true
        if(error.response.data.msg != undefined){
          this.errorMessage = error.response.data.msg
        }else{
          this.errorMessage = "服务错误"
        }
      })
    },
    closeMessage(){
      this.isVisible = false
      this.errorMessage = ""
    }
  }
};
</script>
<style lang="less" scoped>
.top-bg {
  height: 10rem;
  width: 100vw;
  border-radius: 0 0 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  z-index: -1;

  .white-card {
    width: 660px;
    height: 82%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
    border-radius: 24px;
    overflow-x: hidden;
    margin: 40px auto;
    position: relative;

    .info-box {
      width: 100%;
    }

    .step-box {
      width: 311px;
      background-color: #ebebf3;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
      border-radius: 24px;
      position: relative;

      img {
        width: 221px;
        height: 54px;
        margin: 20px 40px;
      }
    }

    .step2-img {
      margin-top: -20px;
    }

    .bottom-box {
      margin-top: 66px;
    }
  }

  .next-step-box {
    width: 660px;
    height: 50px;
    padding: 16px 0;
    background-color: #3d62ad;
    border-radius: 36px;
    text-align: center;
    margin: 0 auto;

    .step-text {
      margin-top: 0.1rem;
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}

.swipe-box .van-swipe-item {
  position: relative;
  overflow: hidden;
  cursor: grab;
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
}

.video-describe {
  position: absolute;
  top: 1.8rem;
  right: 4.4rem;
  width: 0.8rem;
  height: 0.8rem;
}

.video-describe-span {
  font-size: 24px;
  position: absolute;
  top: 1.7rem;
  right: 0.2rem;
  width: 4rem;
  height: 2rem;
}

.van-dialog {
  position: fixed;
  top: 45%;
  left: 50%;
  width: 15rem;
  height: 14vh;
  overflow: hidden;
  font-size: 0.8rem;
  transf: translate3d(-50%,-50%,0);
  backface-visibility: hidden;
  transition: .3s;
  z-index: 9999;
  .header {
    height: 6vh;
    font-weight: 500;
    line-height: 8vh;
    text-align: center;
    vertical-align: bottom;
  }
  .footer {
    height: 6vh;
    padding-top: 0.5rem;
  }
  .van-button {
    border-top: 0.2px solid #d0d0d0;
  }
  .confirm-span {
    color: #1989fa;
  }
}
</style>
<style lang="less" scoped>
.white-card {
  /deep/ .van-swipe {
    /* 自定义样式 */
    height: 75vh;
  }
}

.swipe-box {
  margin: 0 32px;
  .van-swipe-item {
    background-color: #fff;
    //height: auto;
    padding-top: 4vh;
  }

  /deep/ .van-swipe__track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
