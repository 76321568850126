<template>
    <div class>
      <video class="video_class" controls autoplay :src="videoUrl"></video>
    </div>
</template>
<script>
  export default {
    data() {
        return {
            videoUrl: "",
        }
    },
    created() {
      this.videoUrl = "/landifile/landi/default/video/ydsp.mp4"
    }
  };
</script>
<style lang="less" scoped>
    .video_class {
        width: 100%;
        height: 20%;
        padding-top:70%;
    }
</style>