<template>
  <div id="app">
    <router-view
        :setHeaderLeft="setHeaderLeft"
        :setLeftText="setLeftText"
        :setHeaderRight="setHeaderRight"
        :setHeaderTitle="setHeaderTitle"
    />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
  computed:{
  },
  methods:{
    // 设置header title
    setHeaderTitle (title = '') {
      if (!title) return
      this.headerTitle = title
    },
    // 设置左侧文字
    setLeftText (title = '') {
      if (!title) return
      this.leftText = title
    },
    // 设置头部左侧点击事件
    setHeaderLeft (event = () => { }) {
      this.leftEvent = event
    },
    // header右侧按钮配置，handle实践，label显示文本,icon
    setHeaderRight (options = { handle: () => { }, label: '', icon: '', iconSize: '' }) {
      this.rightOptions = options
    },
    clickLeft () {
      this.$router.back()
    }
  }
};
</script>

<style>
@font-face {
  src: url("./fonts/YeZiGongChangAoYeHei-2.ttf");
  font-weight: normal;
  font-style: normal;
  font-family: YEFONTAoYeHei;
}
#app {

}
</style>
