<template>
  <div class="album-camera-box flex-between">
      <div class="step-box step2-img flex-center">
        <img class="selection-img" :src="cardBgLeft" alt="">
        <div class="common dh-icon">
          <img :src=" iconLeft" alt="" />
        </div>
      </div>
      <div class="step-box step2-img  flex-center" @click="handleSelect">
        <img  class="selection-img" :src="cardBgRight" alt="">
        <div class="common psm-icon">
          <img :src="iconRight" alt="" />
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'AlbumCamera',
  data(){
    return {
    }
  },
  props: {
    cardBgLeft: {
      type: String,
      default: ''
    },
    cardBgRight: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('child-event', '来自子组件的数据');
    },
    handleSelect(){
      this.$emit('onSubTrigger')
    }
  }
}
</script>
<style lang="less" scoped>
.album-camera-box {
  width: 100%;
  margin-top: 56px;
  .step-box {
    width: 220px;
    height: 100px;
    background-color: #ebebf3;
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.35);
    border-radius: 24px;
    position: relative;
    .selection-img {
      width: 158px;
      height: 33px;
      text-align: center;
    }
  }
  .common {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -20px;
    left: -16px;
  }
  .dh-icon {
    //top: 5px;
  }
  .psm-icon {
    top: -26px;
  }
}

</style>