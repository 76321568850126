<template>
  <div class="container photo-review-page">
    <div class="portrait-box flex-column-center">
      <div class="loading">
        <img  src="@/assets/images/loading.png" alt="" />
      </div>
      <div class="waiting flex-center">正在审核照片，请稍等……</div>
    </div>
    <van-popup v-model="showPopup" position="bottom" class="custom-popup" :style="{ height: '30%' }" round>
      <div class="popup-box">
        <div class="popup-top flex">
          <div class="left-img"></div>
          <div class="status-text">合格审核通过</div>
        </div>
        <div class="next-step-box" @click="onNextStep">
          <div class="step-text">下一步</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data(){
    return {
      showPopup: true
    }
  },
  methods: {
    onNextStep(){

    }
  }
}
</script>
<style scoped lang="less">
.photo-review-page {
  background-color: #87b3e0;
  padding: 150px 123px 0;
  .portrait-box {
    width: 500px;
    height: 620px;
    background: #fff;
    .loading {
      width: 246px;
      height: 246px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .waiting {
      width: 100vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 42px;
      color: #2060A2;
      margin-top: 150px;
      font-weight: bold;
    }
  }
  .popup-box {
    padding: 50px;
    .popup-top {
      display: flex;
      align-items: center;
      .left-img {
        width: 100px;
        height: 100px;
        background-image: url("~@/assets/images/right.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 30px;
      }
      .status-text {
        font-family: Adobe Heiti Std;
        font-weight: normal;
        font-size: 46px;
        color: #04c48b;
        line-height: 72px;
      }
    }
  }
  .next-step-box {
    padding: 16px 0;
    background-color: #3d62ad;
    border-radius: 36px;
    text-align: center;
    margin: 36px auto 0;
    .step-text {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}
/* 自定义Popup样式 */
.custom-popup .van-overlay::before {
  border-radius: 30px 30px 0 0!important; /* 修改圆角大小 */
}
</style>