import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '@/views/Home'
import MobileShooting from '@/views/MobileShooting.vue'
import SpecificationDetails from '@/views/SpecificationDetails.vue'
import ShootingBranches from '@/views/ShootingBranches.vue'
import MachineShooting from '@/views/MachineShooting.vue'
import PhotoReview from '@/views/PhotoReview.vue'
import ExampleReference from '@/views/ExampleReference.vue'
import VideoPlayer from '@/views/VideoPlayer.vue'
// import Test from '@/views/test.vue'
const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    name: "Home",
    path: '/home',
    component: Home,
    meta: {
      photoCode: '001'
    }
  },
  {
    name: "MobileShooting",
    path: '/mobileshooting',
    component: MobileShooting,
  },
  {
    name: "SpecificationDetails",
    path: '/specificationdetails',
    component: SpecificationDetails,
  },
  {
    name: "ShootingBranches",
    path: '/shootingbranches',
    component: ShootingBranches,
  },
  {
    name: "MachineShooting",
    path: '/machineshooting',
    component: MachineShooting,
  },
  {
    name: "PhotoReview",
    path: '/photoreview',
    component: PhotoReview,
  },
  {
    name: "ExampleReference",
    path: '/examplereference',
    component: ExampleReference,
  },
  {
    name: "VideoPlayer",
    path: '/videoplayer',
    component: VideoPlayer,
  },
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next)=>{
//   const token = localStorage.getItem('h5-token')

//   // 存在
//   if(token){
//     next()   // 放行
//   } else {
//     if(to.path === '/login' || to.path === '/register'){
//       next()
//     } else {
//       next('/login')
//     }
//   }
// })

export default router
