<template>
  <div class="container">
      <div id="container">
        <div class="position-img-box">
          <div class="location-box" @click="handleLocation">
            <img src="@/assets/images/position.png" alt="">
          </div>
          <div class="jump-box" @click="onJump">
            <img src="@/assets/images/change.png" alt="" />
            <div class="jump-text" :style="{ color: backgroundColor }">手机拍照</div>
          </div>
        </div>
      </div>
  </div>
  </template>
  <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  import LocalMark from "@/assets/images/zzj.png"
  export default {
    name: "map-view",
    props: ["marks","fontColor"],
    data() {
      return {
        backgroundColor : "#000000",
        geolocation: null,
        map: null,
        marker: null,
        markerContent: null,
        centerIcon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'
      }
    },
    mounted() {
      this.initData();
      this.initAMap();
    },
    unmounted() {
      this.map?.destroy();
    },
    methods: {
      initData() {
        this.markerContent = '<div class="custom-marker"></div>'
        this.backgroundColor=this.$localStorage.get('styleColor')
      },
      initAMap() {
        AMapLoader.reset()
        AMapLoader.load({
          key: "cb86aee8c87e80da16beae4b3fb333a3",
          version: "2.0",
          plugins: ['AMap.Geolocation','AMap.ToolBar'],
        })
          .then((AMap) => {
            // 初始化地图
            this.map = new AMap.Map('container', {
              zoom: 16,
              viewMode: "2D",
              resizeEnable: true,
            });

            // 加载Geolocation插件
            this.geolocation = new AMap.Geolocation({
              enableHighAccuracy: true,
              maximumAge: 0,
              timeout: 30000,
              convert: true,
              showCircle: false,
              buttonPosition: 'RB',
              buttonOffset: new AMap.Pixel(10, 20),
              zoomToAccuracy: true,
            });

            this.map.addControl(this.geolocation);
            
            // 获取当前位置信息
            this.geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                this.onComplete(AMap, result);
              } else {
                this.onError(result);
              }
            });
            // 标记自助机位置
            this.markMachine(AMap)

            // 添加控制条插件
            const toolBar = new AMap.ToolBar({
            });
            this.map.addControl(toolBar);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      markMachine(AMap){
        const markerList = this.marks.map((mark)=>{
          const positionLngLat = new AMap.LngLat(mark.position.lng, mark.position.lat)
          const markerImg = `<div><img style="width: 34px; height: 38px;" src="${ LocalMark }" /></div>`
          const marker = new AMap.Marker({
            position: positionLngLat,
            map: this.map,
            content: markerImg,
            offset: new AMap.Pixel(-15, -19),
          });
          this.map.add(marker)
        })
      },
      onComplete(AMap, data) {
        let lnglat = data.position;
        if (this.marker) {
          this.map.remove(this.marker);
        }
        const temp = this.wgs84ToGcj02(lnglat.lat,lnglat.lng)
        lnglat.lat = temp[0]
        lnglat.lng = temp[1]
        this.marker = new AMap.Marker({
          position: lnglat,
          map: this.map,
          //icon: this.centerIcon,
          //content: this.markerContent
        });
        this.map.setCenter(lnglat);
      },
      onError() {
      },
      handleLocation(e){
        this.geolocation.getCurrentPosition((status, result) => {
        if (status === 'complete') {
          let lnglat = result.position;
          const temp = this.wgs84ToGcj02(lnglat.lat,lnglat.lng)
          lnglat.lat = temp[0]
          lnglat.lng = temp[1]
          this.map.setCenter(lnglat);
        } else {
        }
      });
      },
      onJump(){
        this.$router.push('/mobileshooting');
      },
      transformLat(x, y) {
        let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(y * Math.PI) + 40.0 * Math.sin(y / 3.0 * Math.PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(y / 12.0 * Math.PI) + 320 * Math.sin(y * Math.PI / 30.0)) * 2.0 / 3.0;
        return ret;
      },
      transformLon(x, y) {
          let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
          ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0;
          ret += (20.0 * Math.sin(x * Math.PI) + 40.0 * Math.sin(x / 3.0 * Math.PI)) * 2.0 / 3.0;
          ret += (150.0 * Math.sin(x / 12.0 * Math.PI) + 300.0 * Math.sin(x / 30.0 * Math.PI)) * 2.0 / 3.0;
          return ret;
      },
      wgs84ToGcj02(lat, lon) {
        const a = 6378245.0;
        const ee = 0.00669342162296594323;
        let dLat = this.transformLat(lon - 105.0, lat - 35.0);
        let dLon = this.transformLon(lon - 105.0, lat - 35.0);
        const radLat = lat / 180.0 * Math.PI;
        let magic = Math.sin(radLat);
        magic = 1 - ee * magic * magic;
        const sqrtMagic = Math.sqrt(magic);
        dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * Math.PI);
        dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * Math.PI);
        const mgLat = lat + dLat;
        const mgLon = lon + dLon;
        return [mgLat, mgLon];
      }
    },
  };
  </script>
  <style lang="less" scoped>
  .container {
    position: relative;
    overflow-y: hidden;
    scrollbar-width: none;
    .location-box {
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: 24vh;
      right: 20px;
      z-index: 10000;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .marker-img {
      width: 50px;
      height: 50px;
    }
  }
  #container {
    width: 100%;
    height: 100%;
  }
  .position-img-box {
    position: absolute;
    top: 77%;
    right: 5px;
  }
  .jump-box {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 18vh;
    right: 20px;
    z-index: 10000;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
    }
    .jump-text {
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-size: 24px;
      color: #1A5798;
      white-space: normal;
    }
  }
  </style>
<style lang="less">
.amap-controls {
  position: absolute;
  right: -10px !important;
  top: 50% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .amap-geolocation{
    display: none;
  }
  
}
.amap-logo {
  display: none !important;
}
.amap-copyright {
  display: none !important;
}
.amap-toolbar {
  width: 60px !important;
  border-radius: 8px;
  padding: 10px 0;
  text-align: center;
  span {
    font-size: 50px !important;
    display: inline-block;
    text-align: center;
  }
  span:hover {
    font-weight: normal;
    background: none;
  }
}
.custom-marker{
  right: -10px !important;
  width: 50px; 
  height: 50px;
  background: url(https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png);
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
